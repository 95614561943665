








































































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

@Component
export default class ProductForm extends Vue {
  @Prop() brandId!: number
  public product: { name: string, section: string, description: string, images: any[], brandId: number, ageRestricted: number } = {
    name: '',
    section: '',
    description: '',
    images: [],
    brandId: this.brandId,
    ageRestricted: 0
  }
  public croppers: any[] = new Array(4)
  public productImages: any[] = new Array(4)
  public imageSelected: boolean[] = new Array(4)
  public imageNames: any[] = new Array(4)
  public busy: boolean = false
  public limitPerPage: number = 100
  public offset: number = 0
  public productSections: any[] = []

  generateImage (index: number) {
    let url = this.croppers[index].generateDataUrl()
    if (!url) {
      return
    }

    Vue.set(this.productImages, index, url)
  }

  addProduct () {
    for (var i = 0; i < 4; ++i) this.generateImage(i)
    this.$validator.validateAll('add-product').then((result) => {
      if (result && !this.busy) {
        this.busy = true
        this.productImages.forEach((x, index) => {
          if (x && x.length > 0) this.product.images.push({ 'image': x, 'description': this.imageNames[index] })
        })
        this.$store.dispatch('addProduct', this.product).then(() => {
          this.$emit('added')
          this.resetForm()
          this.getProductSections()
          this.$notify({ type: 'success', text: 'Product has been added successfully' })
          this.busy = false
        }, (response) => {
          this.$emit('failed')
          for (var key in response.body) {
            this.$notify({ type: 'error', text: response.body[key][0] })
            let field = this.$validator.fields.find({ name: key, scope: 'add-product' })

            if (field) {
              this.$validator.errors.add({
                id: field.id,
                field: key,
                msg: response.body[key][0],
                scope: 'add-product'
              })

              // field.setFlags({
              //   invalid: true,
              //   valid: false,
              //   validated: true
              // })
            }
          }
          this.busy = false
        })
      } else {
        this.$emit('failed')
      }
    })
  }

  resetForm () {
    this.croppers.forEach((x) => x.remove())
    let croppers = this.croppers
    Object.assign(this.$data, (this.$options.data! as any).apply(this))
    this.croppers = croppers
    this.$nextTick(() => this.$validator.errors.clear('add-product'))
  }

  getProductSections (clear: boolean = true): void {
    this.$store.dispatch('getProductSections', { limit: this.limitPerPage, offset: this.offset }).then((response: any) => {
      this.offset += this.limitPerPage
      this.productSections.push(...response.response.body.data)
      if (this.offset < response.response.body.count) {
        this.getProductSections()
      }
    })
  }
  created () {
    this.getProductSections()
  }
}
